import React from "react"
import { Link } from "gatsby"
import { slide as Menu } from 'react-burger-menu'
import './../css/navbar.css'
import logo from './../img/homepage/logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faLinkedin, faInstagram, faGithubSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons'

class NavBar extends React.Component {
  showSettings (event) {
    event.preventDefault();
  }

  render () {
    return (
      <div className="nav">
      <Link to = '/'><a className="logo" alt = 'logo' css = {{fontSize:`xx-large`, fontWeight:`600`, top:`0.7rem`,color:`#373a47`, float:'left', marginBottom: '0', '@media (max-width: 900px)': {width:`3.5rem`}}}><b>BB</b></a></Link>
      <Menu right disableAutoFocus css = {{color:`black`, height:`2rem`, zIndex:`5000`}}>
        <Link id="home" className="menu-item" to="/">HOME</Link>
        <Link id="stats" className="menu-item" to="/stats">STATS</Link>
        <Link  id="projects" className="menu-item" to="/projects">WORK</Link>
        <Link  id="projects" className="menu-item" to="/journeys">JOURNEYS</Link>
        <a css = {{marginBottom:`1rem`}} className = "menu-item" href = "mailto:brett@brettbejcek.com" target='_blank' rel="noopener noreferrer">CONTACT</a>
        <hr css = {{marginBottom:`1rem`}}/>
        <a className = "socialLink" href = "https://facebook.com/brett.bejcek" target='_blank' rel="noopener noreferrer"><FontAwesomeIcon color = "gray" icon={faFacebookSquare} />&nbsp; &nbsp;FACEBOOK</a>
        <a className = "socialLink" href = "https://instagram.com/bbejcek" target='_blank' rel="noopener noreferrer"><FontAwesomeIcon color = "gray" icon={faInstagram} />&nbsp; &nbsp;INSTAGRAM</a>
        <a className = "socialLink" href = "https://linkedin.com/in/brettbejcek" target='_blank' rel="noopener noreferrer"><FontAwesomeIcon color = "gray" icon={faLinkedin} />&nbsp; &nbsp;LINKEDIN</a>
        <a className = "socialLink" href = "https://github.com/bmbejcek" target='_blank' rel="noopener noreferrer"><FontAwesomeIcon color = "gray" icon={faGithubSquare} />&nbsp; &nbsp;GITHUB</a>
        <a className = "socialLink" css = {{marginBottom:`1rem`}} href = "https://twitter.com/brettbejcek" target='_blank' rel="noopener noreferrer"><FontAwesomeIcon color = "gray" icon={faTwitterSquare} />&nbsp; &nbsp;TWITTER</a>
      </Menu>
      </div>
    );
  }
}

export default NavBar
