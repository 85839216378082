import React from "react"
import { Link } from "gatsby"
import Fade from 'react-reveal/Fade'
import './../css/footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faLinkedin, faInstagram, faGithubSquare } from '@fortawesome/free-brands-svg-icons'

class Footer extends React.Component {
  componentDidMount () {
    var TxtType = function(el, toRotate, period) {
            this.toRotate = toRotate;
            this.el = el;
            this.loopNum = 0;
            this.period = parseInt(period, 10) || 2000;
            this.txt = '';
            this.tick();
            this.isDeleting = false;
        };

        TxtType.prototype.tick = function() {
            var i = this.loopNum % this.toRotate.length;
            var fullTxt = this.toRotate[i];

            if (this.isDeleting) {
              if(fullTxt.substring(this.txt.length, this.txt.length - 1)!=='<' && fullTxt.substring(this.txt.length, this.txt.length - 1)!=='>')
              {this.txt = fullTxt.substring(0, this.txt.length - 1);}
              else {
                this.txt = fullTxt.substring(0, this.txt.length - 4);
              }
            } else {
            if(fullTxt.substring(this.txt.length, this.txt.length + 1)!=='<' && fullTxt.substring(this.txt.length, this.txt.length + 1)!=='>')
            {this.txt = fullTxt.substring(0, this.txt.length + 1);}
            else {
              this.txt = fullTxt.substring(0, this.txt.length + 2);
            }
            }

            this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

            var that = this;
            var delta = 200 - Math.random() * 100;

            if (this.isDeleting) { delta /= 2; }

            if (!this.isDeleting && this.txt === fullTxt) {
            delta = this.period;
            this.isDeleting = true;
            } else if (this.isDeleting && this.txt === '') {
            this.isDeleting = false;
            this.loopNum++;
            delta = 500;
            }

            setTimeout(function() {
            that.tick();
            }, delta);
        };

            var elements = document.getElementsByClassName('wrapcontainer');
            for (var i=0; i<elements.length; i++) {
                var toRotate = elements[i].getAttribute('data-type');
                var period = elements[i].getAttribute('data-period');
                if (toRotate) {
                  new TxtType(elements[i], JSON.parse(toRotate), period);
                }
            }

  }
render() {
  return (
    <div css={{background:`#202020`, borderRadius:`20px`, marginBottom:`-4rem`,paddingBottom:`4rem`, '@media (max-width: 750px)':{marginLeft:`5%`, marginRight:`5%`}}}>
    <Fade>
    <div css={{textAlign:`left`, margin:`0 10% 0 10%`, gridGap: `3rem`, '@media (max-width: 900px)': {margin:`0 10% 0 10%`}}}>
    <div>
    <h1 css = {{paddingTop: `4rem`, color:`white`}}> Who is Brett? </h1>
    <p className="typewrite" css={{fontSize:`x-large`, color:`white`, '@media (max-width: 900px)': {fontSize:`larger`,width:`110%`}, '@media (max-width: 365px)': {minHeight:`4rem`,width:`110%`}}}>
    I am a <span className="wrapcontainer" css={{color:`#7d4aa3`, marginLeft:`0.15rem`}}data-period="4000" data-type='[ " data scientist.", " mind mapping enthusiast.", " terrible singer.", " scuba school dropout.", " morning alarm snoozer.", " cold brew addict.", " pro at Wii bowling."]'>
    <span className="wrap" >
    </span>
    </span></p>
    <span css={{color:`white`}}>I live by my top values of <em><b> exploration, iteration, </b></em> and <em><b> connection</b></em>.
    I am an avid journaler, a side project connoisseur, and a coffee chat enthusiast. All my work ladders up to building a world where people: act with intentionality, listen with curiosity, and build community through vulnerability. My thoughts are my own and do not represent clients or employers.</span>
    <div css={{textAlign:`right`}}>
    </div>

  </div>
  <div css = {{display:`table`, height:`100%`}}>
  <div css = {{display: `table-cell`, verticalAlign:`middle`}}>
    </div>
    </div>
    <br/>
    <hr css = {{height:`.2rem`,backgroundColor: `white`}} />
    <div className = "footer_info" css = {{display:`flex`}}>
    <p css = {{color:`gray`, marginRight:`.5rem`}}>© BRETT BEJCEK </p>
    <div className="icons" css = {{display:`grid`, gridTemplateColumns:`repeat(4, 1fr)`, gridGap:`.5rem`}}>
    <a href = "https://facebook.com/brett.bejcek" target='_blank' rel="noopener noreferrer"><FontAwesomeIcon color = "gray" icon={faFacebookSquare} /></a>
    <a href = "https://instagram.com/bbejcek" target='_blank' rel="noopener noreferrer"><FontAwesomeIcon color = "gray" icon={faInstagram} /></a>
    <a href = "https://linkedin.com/in/brettbejcek" target='_blank' rel="noopener noreferrer"><FontAwesomeIcon color = "gray" icon={faLinkedin} /></a>
    <a href = "https://github.com/bmbejcek" target='_blank' rel="noopener noreferrer"><FontAwesomeIcon color = "gray" icon={faGithubSquare} /></a>
    </div>
    </div>
      </div>

    </Fade>

  </div>
)
}
}

export default Footer;

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 200, maxHeight: 200) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  `
